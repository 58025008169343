.div-bg-appbar{
  height: 140px;
  background: rgb(204,41,244);
  background: linear-gradient(234deg, rgba(204,41,244,1) 0%, rgba(155,97,249,1) 100%);
}

.mt14{
  margin-top: 14px;
}

.div-appbarclass{
  position: absolute;
  top: -30px;
  width: 100%;
}

.dropzone-div{
  width: 100%;
  border: 1px dashed;
  border-radius: 4px;
  text-align: center;
  min-height: 200px;
  align-items: center;
  display: flex;
  justify-content: center;
 
}

.dropzone-div-section{
  width: 100%;
  height: 100%;
}

.dropzone-div-section div{
  width: 100%;
  height: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
}

.dropzone-div-section div input{
  cursor: pointer;
}

.title-card-materi{
  color: white;
  font-size: 18px;
  font-weight: 500;
}

.div-card-title{
  position: absolute;
  top: 20px;
  left: 20px;  
  z-index: 100;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: calc(100% - 40px);
}

.menu-item-setting.menu-item-setting{
  font-size: 12px;
  color: #999999;
  padding: 2px 10px;
}

.div-des{
  display: flex;
}

.div-out-des{
  display: flex;
  justify-content: center;
}

.div-des-in{
  background-color: #f4d1fd;
  border-radius: 20px;
  padding: 16px;
  width: 80%;
}

.css-1d6y1ul-MuiCardContent-root:last-child.css-1d6y1ul-MuiCardContent-root:last-child{
  padding-bottom: 16px;
}
