$phone: 600px;
$tablet: 1023px;
$dekstop: 1024px;

///login
.logo-login{
    width: 150px;
}

.root-login{
    height: 100vh;
}
  
.bg-login{
    width: 100%;
}

.div-img-input{
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 12px;
}
  
.box-left-login{
    padding: 5vw;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgb(255, 255, 255);
    // border-right: 1px solid rgb(206, 200, 200);
    // background-image: url(../images/bg-3.jpg);
    // background-size: cover;
    @media screen and (max-width: $phone){
      display: none;
    }
}
  .title-head-login{
      font-weight: 800;
      margin: 10px 0px;
    text-align: center;
    font-size: 22px;
    // color:#f1f50b;
    // margin-bottom: px;
    @media screen and (max-width: $phone){
      font-size: 18px;
    }
  }
  
  .head-login{
      font-weight: 600;
      margin: 10px 0px;
    text-align: center;
    font-size: 18px;
    // color:#f1f50b;
    margin-bottom: 12px;
    @media screen and (max-width: $phone){
      font-size: 16px;
    }
  }
  
  .sub-head-login{
    font-size: 15px;
    text-align: center;
    @media screen and (max-width: $phone){
      font-size: 13px;
    }
  }
  
  .box-form{
    padding: 40px;
    display: flex;
    flex-direction: column !important;
    justify-content: center;
    align-items: center;
    height: calc(100% - 120px);
  }
  
  .btn-login{
    margin-top: 12px;
    margin-bottom: 30px;
    width: 100%;
  }

  .div-avatar-detail {
    border-radius: 20px 20px 0px 0px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 70px;
    background-image: linear-gradient( 83.2deg,  rgba(150,93,233,1) 10.8%, rgba(99,88,238,1) 94.3% );
  }

  .img-404{
    @media screen and (max-width: $phone){
      width: 100%;
    }
  }
  
  ///

  .drawer.drawer{
    background-image: linear-gradient(335deg, rgba(222,0,251,1) 8%, rgba(121,0,251,1) 100%);
    color: white;
  }